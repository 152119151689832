import React, { useState } from 'react';

const EmailForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    age: '',
    gender: '',
    phoneNumber: ''
  });
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch('/api/submit-email', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer pat-na1-9fb64e8c-b29b-4b72-9ae7-dc903f3e98d2',
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setMessage('Thanks for joining our community! 🎉');
        setFormData({
          email: '',
          firstName: '',
          lastName: '',
          age: '',
          gender: '',
          phoneNumber: '',
        });
      } else {
        const result = await response.json();
        setMessage(result.message || 'Something went wrong. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Connection error. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="email-form-container">
      <h2 className="form-title">Join Our Community</h2>
      {message && (
        <div className={`message ${message.includes('Thanks') ? 'success' : 'error'}`}>
          {message}
        </div>
      )}
      <form onSubmit={handleSubmit} className="modern-form">
        <div className="form-group">
          <div className="input-wrapper">
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="First Name"
              required
              className="modern-input"
            />
            <label className="floating-label">First Name</label>
          </div>
          <div className="input-wrapper">
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Last Name"
              required
              className="modern-input"
            />
            <label className="floating-label">Last Name</label>
          </div>
        </div>

        <div className="form-group">
          <div className="input-wrapper">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
              required
              className="modern-input"
            />
            <label className="floating-label">Email</label>
          </div>
          <div className="input-wrapper">
            <input
              type="tel"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              placeholder="Phone Number"
              required
              className="modern-input"
            />
            <label className="floating-label">Phone Number</label>
          </div>
        </div>

        <div className="form-group">
          <div className="input-wrapper">
            <input
              type="number"
              name="age"
              value={formData.age}
              onChange={handleChange}
              placeholder="Age"
              className="modern-input"
            />
            <label className="floating-label">Age</label>
          </div>
          <div className="input-wrapper">
            <select
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              className="modern-select"
            >
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="non-binary">Non-binary</option>
              <option value="prefer-not-to-say">Prefer not to say</option>
            </select>
          </div>
        </div>

        <button 
          type="submit" 
          className={`submit-button ${isSubmitting ? 'submitting' : ''}`}
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : 'Join Now'}
        </button>
      </form>
    </div>
  );
};

export default EmailForm;