import React, { useEffect } from 'react';
import EmailForm from '../components/EmailForm';
import PartnersSection from '../components/Partners';

const Home = () => {
  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
      rootMargin: '0px'
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-in');
        }
      });
    }, observerOptions);

    document.querySelectorAll('.animate-on-scroll').forEach((element) => {
      observer.observe(element);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="home-container">
      <div className="hero-section">
        <div className="hero-content">
          <div className="hero-text animate-on-scroll">
            <h1>Family <span className="accent-text">FunsFeed</span></h1>
            <p className="hero-subtitle">Let's Turn Family Time Into Fun Time</p>
          </div>
          <div className="hero-image animate-on-scroll">
            <img src="Logo1.png" alt="Family FunsFeed Logo" className="floating" />
          </div>
        </div>
      </div>

      <div className="mission-section animate-on-scroll">
      <div className="mission-content">
        <h2>Our Mission</h2>
        <p>
          At Family FunsFeed (FFF), our mission is to create a safe and loving space 
          for families to share joy, build lasting memories, and strengthen their bonds, 
          making family time truly special.
        </p>
      </div>
    </div>

      <div className="app-section">
        <div className="app-content animate-on-scroll">
          <div className="store-section">
            <h3>Download Now!!</h3>
            <div className="store-links">
              <a 
                href="https://apps.apple.com/us/app/familyfunsfeed/id6633433807" 
                target="_blank" 
                rel="noopener noreferrer"
                className="store-button"
              >
                <img src="apple.png" alt="Apple App Store" className="store-image hover-lift"/>
              </a>
            </div>
          </div>
          
          <div className="form-section">
            <EmailForm />
          </div>
          
        </div>
      </div>
      <PartnersSection />
    </div>
  );
};

export default Home;