import React from 'react';

const PartnersSection = () => {
  return (
    <div className="partners-section">
      <h3>Our Growing Team of Services Partners</h3>
      <div className="partners-logos">
        <img src="lynn-frc-logo.png" alt="Lynn FRC" />
        <img src="fcps (2).png" alt="Fairfax County Public Schools" />
      </div>
    </div>
  );
};

export default PartnersSection;
