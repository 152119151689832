// src/components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';

const EULA = () => {
    return (
        <div className = "privacy">
    <h1>End User License Agreement</h1>

    <h2>1. Acceptance of Terms</h2>
    <p>By using FamilyFunsFeed (the "App"), you agree to comply with and be bound by this End User License Agreement ("Agreement"). If you do not agree, you must not access or use the App.</p>

    <h2>2. Prohibited Conduct</h2>
    <p>You agree not to engage in any behavior that:</p>
    <ul>
        <li>Promotes or shares objectionable, offensive, or illegal content.</li>
        <li>Harasses, abuses, or otherwise harms other users.</li>
        <li>Violates any applicable laws or regulations.</li>
        <li>Interferes with the App's security, functionality, or integrity.</li>
    </ul>

    <h2>3. Intolerance for Abusive Behavior</h2>
    <p>FamilyFunsFeed has a zero-tolerance policy for abusive users or objectionable content. If you violate this policy:</p>
    <ul>
        <li>Your account may be immediately suspended or terminated without prior notice.</li>
        <li>Any content deemed objectionable will be removed.</li>
    </ul>

    <h2>4. User Responsibilities</h2>
    <p>You are solely responsible for the content you create, share, or interact with within the App. You must report any abusive behavior or objectionable content to <a href="mailto:admin@familyfunsfeed.com">admin@familyfunsfeed.com</a>.</p>

    <h2>5. Content Monitoring and Enforcement</h2>
    <p>FamilyFunsFeed reserves the right to monitor, review, and moderate all content within the App to ensure compliance with this Agreement.</p>

    <h2>6. Agreement Updates</h2>
    <p>We reserve the right to modify this Agreement at any time. Continued use of the App constitutes acceptance of the updated Agreement.</p>

    <h2>7. Termination</h2>
    <p>This Agreement is effective until terminated by either you or FamilyFunsFeed. Misuse of the App or violation of these terms may result in immediate termination of your access.</p>

    <h2>8. Disclaimer of Liability</h2>
    <p>FamilyFunsFeed is not responsible for user-generated content and does not guarantee the App will meet all user expectations or remain uninterrupted.</p>

    <p>By clicking "I Agree" during account registration, you confirm that you have read, understood, and accepted this Agreement.</p>
   </div>
            
    );
};

export default EULA;
